import React, { ReactElement } from "react"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import Seo from "../components/SEO"

import { styled } from "twin.macro"
import Policy from "../components/Policy"

const Main = styled.main`
  font-family: "Lato", "Noto Sans JP", "ヒラギノ角ゴ ProN",
    "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック",
    "MS PGothic", sans-serif;
`

function Index(): ReactElement {
  return (
    <React.StrictMode>
      <Seo
        title="プライバシーポリシー"
        description="プライバシーポリシーについて"
      />
      <Navbar />
      <Main>
        <Policy />
      </Main>
      <Footer />
    </React.StrictMode>
  )
}

export default Index
