import tw, { styled } from "twin.macro"
import * as Common from "../../common/styles"

export const Container = Common.Container

export const Section = styled(Common.Section)`
  ${tw`bg-gray-100 pb-24`}
`

export const SectionSubTitle = Common.SectionSubTitle
export const SectionTitle = styled(Common.SectionTitle)`
  ${tw`md:mb-16`}
`
export const SectionHeader = Common.SectionHeader
export const SectionText = styled(Common.SectionText)`
  ${tw`text-left max-w-full md:text-base`}
`

export const Heading = styled.h3`
  ${tw`text-2xl text-primary text-left pt-4`}
`
export const List = styled.ol`
  ${tw`list-inside list-decimal	 text-left leading-relaxed text-gray-500 mt-4 `}
  ol {
    ${tw`ml-4 md:ml-8  mt-2 `}
  }
  li {
    ${tw`mb-1 `}
  }
`
